import $ from "jquery";

$(document).ready(function($){
    $(".phone").mask("(9999) 999-999");
    $('.validate').bind("change paste keydown", function(){
        var name = $(this).attr("id");
        var tam = $(this).attr("size");
        validarText(this, name + "_div", name + "_message", 2, tam);
    });
});

window.validarText = function(input,div,p,min,max) {
    var txt = $(input).val();
    if (txt.length < min){
        $('#'+div).addClass('field_with_errors');
        $('#'+p).html('*Este campo es requerido y debe ser mayor a ' + min + ' caracteres');
        $("input[name=commit]").prop( "disabled", true );
    } else if (txt.length > max){
        $('#'+div).addClass('field_with_errors');
        $('#'+p).html('*Este campo debe ser menor a ' + max + ' caracteres');
        $("input[name=commit]").prop( "disabled", true );
    } else {
        if($('#'+div).hasClass('field_with_errors')){
            $('#'+div).removeClass('field_with_errors');
            $('#'+p).html('');
            $("input[name=commit]").prop( "disabled", false );
        }
    }
}

window.completeDevolutionDate = function(input) {
    const days = parseInt($(input).val());
    const newDate = new Date();
    newDate.setDate(newDate.getDate() + days);
    $("#book_lending_devolution_date").val(newDate.toJSON().slice(0,19));
    $("input[name=commit]").prop( "disabled", false );
}

window.completeReturnedDate = function(input) {
    const returned = $(input).is(':checked');
    if (returned) {
        const newDate = new Date();
        $("#book_lending_returned_date").val(newDate.toJSON().slice(0,19));
    } else {
        $("#book_lending_returned_date").val(null);
    }
    $("input[name=commit]").prop( "disabled", false );
}

window.showWorkArea = function (value) {
    if (value.includes('Alumno') || value.includes('Socio') ) {
        $("#workingAreaSection").css("display","none");
    } else {
        $("#workingAreaSection").css("display","block");
    }
}